/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

export function SubmitDialog({ id, show, onHide, onSubmit, message }) {
  const intl = useIntl();

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
        {intl.formatMessage({ id: "confirmation" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{message}</span>
        {/* <div>
        <TextField label='Reject reason' className='w-100' />
        </div> */}
        {/* {isLoading && <span>Product is deleting...</span>} */}
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none" }}>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light ">
            {intl.formatMessage({ id: "CANCEL" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={onSubmit}
            className="btn btn-light-primary "
          >
            {intl.formatMessage({ id: "SUBMIT" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
