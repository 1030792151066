import React from "react";
import { useChatContext } from "../../../context/chatContext";
import { ChatBox } from "./ChatBox";
export function Chat() {
  const [state] = useChatContext();
  
  return (
    <div className="chat-container">
      {state && state.map((item) => <ChatBox key={item.id} ticket={item} />).reverse()}
    </div>
  );
}
