import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($user_name: String!, $password: String!) {
    login(user_name: $user_name, password: $password) {
      error
      token {
        access_token
        token_type
      }
    }
  }
`;

export const CREATE_STAFF = gql`
  mutation createStaff($details: StaffCreation!) {
    create_staff(details: $details) {
      error
      staff {
        id
        user_name
        group
        email
        active
        name
        designation
        created_at
        updated_at
      }
    }
  }
`;
export const UPDATE_STAFF = gql`
  mutation updateStaff($id: UUID!, $details: StaffUpdation!) {
    update_staff(id: $id, details: $details) {
      error
      staff {
        id
        user_name
        group
        email
        active
        name
        designation
        created_at
        updated_at
      }
    }
  }
`;
export const CREATE_ADVERTISER = gql`
  mutation createAdvertiser($details: AdvertiserCreation!) {
    create_advertiser(details: $details) {
      error
      advertiser {
        id
        user_name
        email
      }
    }
  }
`;
export const CREATE_CREDIT = gql`
  mutation create_credit($details: CreditCreation!) {
    create_credit(details: $details) {
      error
      credit {
        user_id
        amount
        bank_transaction_id
      }
    }
  }
`;

export const UPDATE_ADVERTISER = gql`
  mutation updateAdvertiser($id: UUID!, $details: AdvertiserUpdation!) {
    update_advertiser(id: $id, details: $details) {
      error
      advertiser {
        id
        user_name
        group
        email
        address_1
        address_2
        city
        state
        postal_code
        company_name
        email_confirmed
        active
        created_at
        updated_at
        phone_cc
        phone_no
        credit
        logo
        logo_image_extension
        logo_uploaded
        status
        status_description
        person_incharge_designation
        person_incharge_name
        company_website_url
        company_industry_area
        country
      }
    }
  }
`;
export const UPDATE_BANNER = gql`
  mutation updateBanner($id: UUID!, $details: BannerUpdation!) {
    update_banner(id: $id, details: $details) {
      error
      banner {
        id
        advertiser_id
        name
        image_uploaded
        link
        image
        image_extension
        created_at
        updated_at
        status
        status_description
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation loginRefresh {
    login_refresh {
      error
      token {
        access_token
        token_type
      }
    }
  }
`;
export const UPDATE_BANNER_POSITION = gql`
  mutation updateBannerPosition($id: UUID!, $details: BannerPositionUpdation!) {
    update_banner_position(id: $id, details: $details) {
      error
      banner_position {
        id
        position
        standard_pricing
      }
    }
  }
`;

export const UPDATE_BANNER_POSITION_DATE_PRICE = gql`
  mutation updateBannerPositionDatePricing(
    $id: UUID!
    $details: BannerPositionDatePricingUpdation!
  ) {
    update_banner_position_date_pricing(id: $id, details: $details) {
      error
      banner_position_date_pricing {
        id
        banner_position_id
        price_on
        price
        created_at
        updated_at
      }
    }
  }
`;
export const CREATE_DATE_PRICE = gql`
  mutation createDatePrice($details: BannerPositionDatePricingCreation!) {
    create_banner_position_date_pricing(details: $details) {
      error
      banner_position_date_pricing {
        banner_position_id
        price_on
        price
      }
    }
  }
`;
export const CREATE_BANNER_POSITION_MONTH_PRICING = gql`
  mutation createBannerPosMPrice(
    $details: BannerPositionMonthPricingCreation!
  ) {
    create_banner_position_month_pricing(details: $details) {
      error
      banner_position_month_pricing {
        id
        banner_position_id
        month
        price
      }
    }
  }
`;
export const UPDATE_BANNER_POSITION_MONTH_PRICING = gql`
  mutation updateBannerPosMPrice(
    $details: BannerPositionMonthPricingUpdation!
    $id: UUID!
  ) {
    update_banner_position_month_pricing(details: $details, id: $id) {
      error
      banner_position_month_pricing {
        id
        banner_position_id
        month
        price
      }
    }
  }
`;

export const UPDATE_PROFESSIONAL = gql`
  mutation updateProfessional($id: UUID!, $details: ProfessionalUpdation!) {
    update_professional(id: $id, details: $details) {
      error
      professional {
        id
        user_name
        group
        email
        first_name
        last_name
        designation
        organization
        specialisation
        email_confirmed
        DOB
        status
        status_description
        active
        created_at
        updated_at
      }
    }
  }
`;
export const UPDATE_PROFESSIONAL_DOC = gql`
  mutation updateProfessionalDoc(
    $id: UUID!
    $details: ProfessionalDocUpdation!
  ) {
    update_professional_doc(id: $id, details: $details) {
      error
      professional_doc {
        doc_type
        status
        status_description
      }
    }
  }
`;

export const CREATE_MEETING_NOTE = gql`
  mutation create_meeting_note($details: MeetingNoteCreation!) {
    create_meeting_note(details: $details) {
      error
      meeting_note {
        id
        user_id
        note
        created_at
        updated_at
        image_uploaded
        image_extension
        image
      }
    }
  }
`;
export const UPDATE_MEETING_NOTE = gql`
  mutation updateMeetingNote($id: UUID!, $details: MeetingNoteUpdation!) {
    update_meeting_note(id: $id, details: $details) {
      error
      meeting_note {
        id
        user_id
        recipient_id
        note
        created_at
        updated_at
        image_uploaded
        image_extension
        image
      }
    }
  }
`;
export const CREATE_MASTERLIST_SPECIALISATION = gql`
  mutation create_masterlist_specialisation(
    $details: MasterlistSpecialisationCreation!
  ) {
    create_masterlist_specialisation(details: $details) {
      error
      masterlist_specialisation {
        major_classification
        middle_classification
        sub_category
        specific_category
        locale
      }
    }
  }
`;

export const UPDATE_ADVERTISER_TICKET = gql`
  mutation updateAdvertiserTicket(
    $id: UUID!
    $details: AdvertiserTicketUpdation!
  ) {
    update_advertiser_ticket(id: $id, details: $details) {
      error
      advertiser_ticket {
        id
        advertiser_id
        meeting_note_id: description
        assigned_to
        status
        status_description
        created_at
        updated_at
      }
    }
  }
`;
export const CREATE_TICKET_CHAT_MESSAGE = gql`
  mutation createTicketChatMessage($details: TicketChatMessageCreation!) {
    create_ticket_chat_message(details: $details) {
      error
      ticket_chat_message {
        id
        ticket_id
        message
        sent_by
        seen
        created_at
      }
    }
  }
`;

export const UPDATE_TICKET_CHAT_MESSAGE = gql`
  mutation updateTicketChatMessage($ticket_id: UUID!, $id: String!) {
    update_ticket_chat_message(ticket_id: $ticket_id, id: $id) {
      error
      ticket_chat_message {
        id
        ticket_id
        message
        sent_by
        seen
        created_at
      }
    }
  }
`;
export const UPDATE_TICKET_CHAT_TYPING = gql`
  mutation updateTicketChatTyping($ticket_id: UUID!) {
    update_ticket_chat_typing(ticket_id: $ticket_id) {
      error
      updated
    }
  }
`;

export const UPDATE_ADVERTISER_REQEUST = gql`
  mutation updateAdvertiserRequest(
    $id: UUID!
    $details: AdvertiserUpdateRequestUpdation!
  ) {
    update_advertiser_update_request(id: $id, details: $details) {
      error
      advertiser_update_request {
        id
        advertiser_id
        status
        status_description
        email
        address_1
        address_2
        city
        state
        country
        postal_code
        company_name
        company_industry_area
        company_website_url
        person_incharge_name
        person_incharge_designation
        person_incharge_phone_cc
        person_incharge_phone_no
        phone_cc
        phone_no
        logo_image_extension
        logo_uploaded
        created_at
        updated_at
      }
    }
  }
`;

export const UPDATE_POST = gql`
  mutation update_post($id: UUID!, $details: PostUpdation!, $tags: [UUID]) {
    update_post(id: $id, details: $details, tags: $tags) {
      error
      post {
        status
      }
    }
  }
`;
export const CREATE_FAQ = gql`
  mutation createFaq($details: FAQCreation!) {
    create_faq(details: $details) {
      error
      faq {
        id
        question
        jpQuestion
        jpAnswer
        answer
        type
        created_at
        updated_at
      }
    }
  }
`;
export const DELETE_FAQ = gql`
  mutation deleteFAq($id: UUID!) {
    delete_faq(id: $id) {
      error
      deleted
    }
  }
`;
export const CLOSE_REPORTED_POST = gql`
  mutation closeReportedPost($id: UUID!, $details: PostVideoReportUpdation!) {
    update_post_video_report(id: $id, details: $details) {
      error
    }
  }
`;
