import React, { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
const ChatContext = createContext();
export function useChatContext() {
  return useContext(ChatContext);
}
export const ChatConsumer = ChatContext.Consumer;

export function ChatContextProvider({ children }) {
  const [state, setState] = useState([]);

  useEffect(() => {
    console.log("chat", state);
  }, [state]);
  const toggleChat = (data) => {
    const currentState = [...state];
    const ticketId = currentState.findIndex((x) => x.id === data);
    currentState[ticketId].minimize = !currentState[ticketId].minimize;
    setState(() => currentState);
  };
  const updateChat = (prop, data) => {
    console.log(data);
    // const currentState = [...state];
    switch (prop) {
      case "toggle":
        return toggleChat(data);
      case "initiate-chat":
        if (!_.some(state, { id: data.id }) && state.length < 3)
          return setState((pr) => [
            ...pr,
            {
              id: data.id,
              minimize: false,
              user_name: data.advertiser.user_name,
              advertiser_id: data.advertiser_id,
              status: data.status,
              description: data.description,
            },
          ]);
      case "close":
        return setState((pr) => pr.filter((item) => item.id !== data));
      default:
        break;
    }

    // setState((pr) => ({ ...pr, [prop]: val }));
  };
  return (
    <ChatContext.Provider value={[state, updateChat]}>
      {children}
    </ChatContext.Provider>
  );
}
