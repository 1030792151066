import axios from "axios";
import { REST_API_ENDPOINT } from "../../config";
let baseURL = "https://connex.eoraa.com/";
const { NODE_ENV } = process.env;
// if (
//   NODE_ENV === "development" ||
//   window.location.hostname.includes("testing")
// ) {
//   baseURL = "https://testing-connex.eoraa.com/";
// }
const locale = JSON.parse(localStorage.getItem("i18nConfig"));
const request = ({ url, body = {}, query, request_type }) => {
  return axios({
    url: REST_API_ENDPOINT + url,
    params: { ...query },
    data: body,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      "Accept-Language": locale ? locale.selectedLang : "en",
    },
  }).catch((err) => console.log(err));
};

export const uploadImage = (id, file) => {
  const data = new FormData();
  data.append("image", file);
  return request({
    url: `meeting_notes/${id}/image?locale=${locale.selectedLang}`,
    body: data,
    request_type: "POST",
  });
};
