import React from "react";
import SkeletonElement from "./SkeletonElement";
import Shimmer from "./shimmer";

const SkeletonTable = ({ theme, hideHeader }) => {
  const themeClass = theme || "light";
  return (
    <div className={`skeleton-wraper ${themeClass} `}>
      <div className="skeleton-table">
        <table className="table">
          {!hideHeader && (
            <thead>
              <tr>
                <th scope="col">
                  <SkeletonElement type="headerr" />
                </th>
                <th scope="col">
                  <SkeletonElement type="headerr" />
                </th>
                <th scope="col">
                  <SkeletonElement type="headerr" />
                </th>
                <th scope="col">
                  <SkeletonElement type="headerr" />
                </th>
                <th scope="col">
                  <SkeletonElement type="headerr" />
                </th>
                <th scope="col">
                  <SkeletonElement type="headerr" />
                </th>
                <th scope="col">
                  <SkeletonElement type="headerr" />
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            <tr>
              <th scope="row">
                <SkeletonElement type="title" />
              </th>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <SkeletonElement type="title" />
              </th>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <Shimmer />
            </tr>
            <tr>
              <th scope="row">
                <SkeletonElement type="title" />
              </th>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <SkeletonElement type="title" />
              </th>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <Shimmer />
            </tr>
            <tr>
              <th scope="row">
                <SkeletonElement type="title" />
              </th>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <SkeletonElement type="title" />
              </th>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <SkeletonElement type="title" />
              </th>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
              <td>
                <SkeletonElement type="title" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SkeletonTable;
