import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import { actions } from "./modules/Auth/_redux/authRedux";
import { Chat } from "./pages/Ticket/chat/Chat";
import { ChatContextProvider } from "./context/chatContext";
import SkeletonFinal from "../app/component/SkeletonFinal";

import { useDispatch, useSelector } from "react-redux";
import { parseJwt } from "./component/UIhelpers";
const DashboardPage = lazy(() => import("./pages/Dashboard/DashboardPage"));
const AdvertiserRoutes = lazy(() =>
  import("./pages/Advertiser/AdvertiserRoutes")
);
const UsersPage = lazy(() => import("./pages/UserContainer/Routes"));
const FlagsPage = lazy(() => import("./pages/Flag/pages/FlagsPage"));
const BannerRoutes = lazy(() =>
  import("./pages/BannerContainer/pages/BannerRoutes")
);
const AdminPage = lazy(() => import("./pages/Admin/adminPage"));
const TicketsPage = lazy(() => import("./pages/Ticket/Routes"));
const Faqs = lazy(() => import("./pages/Faqs/FaqCard"));
const EmailCard = lazy(() => import("./pages/Email/EmailCard"));
const EmailCreate = lazy(() => import("./pages/Email/EmailCreate"));

export default function BasePage() {
  return (
    <Suspense fallback={<SkeletonFinal />}>
      <ChatContextProvider>
        <Chat />
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <Route path="/Advertisers" component={AdvertiserRoutes} />
          <Route path="/User" component={UsersPage} />
          <Route path="/banner" component={BannerRoutes} />
          <Route path="/admin" component={AdminPage} />
          <Route path="/Flag" component={FlagsPage} />
          <Route path="/tickets" component={TicketsPage} />
          <Route path="/faq/:type" component={Faqs} />
          <Route path="/email" exact component={EmailCard} />
          <Route path="/email/create" component={EmailCreate} />

          {/* <Route path="/user-profile" component={UserProfilepage} /> */}
          {/* <Redirect to="error/error-v1" /> */}
        </Switch>
      </ChatContextProvider>
    </Suspense>
  );
}
