/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import { actions } from "./../app/modules/Auth/_redux/authRedux";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useMutation, useQuery } from "@apollo/client";
import { mutate, query } from "./api";
import { differenceInMilliseconds, fromUnixTime, isPast } from "date-fns";
import { parseJwt } from "./component/UIhelpers";

export function Routes() {
  const dispatch = useDispatch();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken != null,
    }),
    shallowEqual
  );
  const { data } = useQuery(query.VALIDATE);
  const [loginRefresh, { error }] = useMutation(mutate.REFRESH_TOKEN);

  const { authToken } = useSelector((state) => state.auth);
  const { exp } = parseJwt(authToken);

  useEffect(() => {
    const expiryTime = differenceInMilliseconds(fromUnixTime(exp), new Date());
    if (isPast(fromUnixTime(exp))) {
      console.log("dispatch logout");
      dispatch(actions.logout());
    } else
      setTimeout(() => {
        dispatch(actions.logout());
      }, expiryTime);
    console.log(expiryTime);
  }, [exp]);

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
