import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { mutate, query, sub } from "../../../api";
import { useChatContext } from "../../../context/chatContext";
import { SubmitDialog } from "../../../component/SubmitDialog";
import { useIntl } from "react-intl";
import { msgDateFormat } from "../../../component/UIhelpers";
import InfiniteScroll from "react-infinite-scroller";
import { TICKET_CHAT_MESSAGES } from "../../../api/queries";
export function ChatBox({ ticket }) {
  const token = localStorage.getItem("token");
  const client = useApolloClient();
  const intl = useIntl();
  const [submitDialog, setSubmitDialog] = useState({
    isOpen: false,
    status: "",
    id: "",
  });
  const [, updateChat] = useChatContext();
  const [hasMore, setHasMore] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inbox, setInbox] = useState([]);
  const [message, setMessage] = useState({ message: "", ticket_id: "" });

  const msgCoutainerRef = useRef(null);
  const { data, loading, refetch, subscribeToMore } = useQuery(
    query.TICKET_CHAT_MESSAGES,
    {
      variables: { ticket_id: ticket.id, reverse: true, limit: 5 },
    }
  );
  // const {
  //   data: subData,
  //   error: subError,
  //   loading: subLoading,
  // } = useSubscription(sub.TICKET_CHAT_MESSAGE, {
  //   variables: { token: token, ticket_id: ticket.id },
  //   fetchPolicy: "network-only",
  // });

  const [updateAdvertiserTicket] = useMutation(mutate.UPDATE_ADVERTISER_TICKET);

  const [createTicketChatMessage] = useMutation(
    mutate.CREATE_TICKET_CHAT_MESSAGE
  );
  const scrollToBottom = () => {
    if (msgCoutainerRef.current) {
      msgCoutainerRef.current.scrollTop =
        msgCoutainerRef.current.scrollHeight -
        msgCoutainerRef.current.clientHeight;
    }
  };
  const sendMessage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (message.message) {
      createTicketChatMessage({
        variables: {
          details: {
            ticket_id: message.ticket_id,
            message: message.message,
          },
        },
      }).then(() => {
        setMessage({ message: "", ticket_id: "" });
        scrollToBottom();
        refetch();
      });
    }
  };

  const handleChange = (id, value) => {
    scrollToBottom();
    setMessage((pr) => ({
      ...pr,
      message: value,
      ticket_id: id,
    }));
  };
  useEffect(() => {
    console.log(message);
  }, [message]);
  const handleSubmit = (id, status) => {
    setSubmitDialog({ isOpen: true, id, status });
  };
  const submit = () => {
    updateAdvertiserTicket({
      variables: {
        id: submitDialog.id,
        details: { status: submitDialog.status },
      },
    }).then(() => {
      updateChat("close", submitDialog.id);
      enqueueSnackbar("Success", { variant: "success" });
    });
  };
  const loadFunc = () => {
    client
      .query({
        query: TICKET_CHAT_MESSAGES,
        variables: {
          start: inbox[inbox.length - 1].id,
          ticket_id: ticket.id,
          reverse: true,
          limit: 5,
        },
      })
      .then((res) => {
        const response = res.data.ticket_chat_messages.ticket_chat_messages;
        setInbox((pr) => [...pr, ...response]);
        setHasMore(response.length === 5);
      });
  };

  useEffect(() => {
    if (!loading && data) {
      setInbox(data.ticket_chat_messages.ticket_chat_messages);
      setHasMore(data.ticket_chat_messages.ticket_chat_messages.length === 5);
    }
  }, [data, loading]);
  useEffect(() => {
    const fetchLatestMsg = () => {
      if (ticket.status !== "resolved") {
        subscribeToMore({
          document: sub.TICKET_CHAT_MESSAGE,
          variables: { token: token, ticket_id: ticket.id },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;
            const newMessage =
              subscriptionData.data.ticket_chat_message.ticket_chat_message;
            console.log(newMessage);
            return Object.assign({}, prev, {
              ticket_chat_messages: {
                ticket_chat_messages: [
                  newMessage,
                  ...prev.ticket_chat_messages.ticket_chat_messages,
                ],
              },
            });
          },
        });
        scrollToBottom();
      }
    };
    fetchLatestMsg();
  }, [ticket]);

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  return (
    <div className="chat" key={ticket.id}>
      <div
        className="chat-header"
        onClick={() => updateChat("toggle", ticket.id)}
      >
        <div className="title">
          <p className="mb-0">{ticket.user_name}</p>
          <small>{ticket.description}</small>
        </div>
        <div className="actions">
          <a
            href="#"
            onClick={(e) => {
              e.stopPropagation();
              updateChat("close", ticket.id);
            }}
          >
            <SVG
              on
              src={toAbsoluteUrl(
                `/media/svg/icons/Code/Error-circle.svg
                  `
              )}
            />
          </a>
          <SVG
            src={toAbsoluteUrl(
              `/media/svg/icons/Code/${
                ticket.minimize ? "Plus.svg" : "Minus.svg"
              }`
            )}
          />
        </div>
      </div>
      <div className={`chat-body ${ticket.minimize && "hide"}`}>
        <div ref={msgCoutainerRef} className="message-container">
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            hasMore={hasMore}
            threshold={10}
            isReverse={true}
            loader={<div>loading...</div>}
            useWindow={false}
          >
            {inbox
              .map((msg) => (
                <div
                  className={`message ${
                    msg.sent_by === ticket.advertiser_id
                      ? "message-left"
                      : "message-right"
                  } `}
                  key={msg.id}
                >
                  <div className="msg-group">
                    <p>{msg.message}</p>
                    <small>
                      {msg.created_at && msgDateFormat(msg.created_at)}
                    </small>
                  </div>
                </div>
              ))
              .reverse()}
          </InfiniteScroll>
        </div>
        {ticket.status !== "resolved" && (
          <div className="footer">
            <div className="action">
              <button
                className="btn btn-secondary py-1"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmit(ticket.id, "resolved");
                }}
              >
                {intl.formatMessage({ id: "resolved" })}
              </button>
              <button
                className="btn btn-secondary py-1"
                onClick={(e) => {
                  e.stopPropagation();
                  updateChat("close", ticket.id);
                }}
              >
                {intl.formatMessage({ id: "hold" })}
              </button>
              <button className="btn btn-secondary py-1" disabled>
                {intl.formatMessage({ id: "Escalate" })}
              </button>
            </div>

            <form onSubmit={sendMessage}>
              <div className="input">
                <input
                  autoComplete="false"
                  placeholder="Type messages here"
                  disabled={ticket.status === "resolved"}
                  value={message?.message}
                  id={ticket.id}
                  onChange={({ target: { value, id } }) =>
                    handleChange(id, value)
                  }
                />
                <button type="submit" className="btn btn-icon btn-sm mx-3">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Send.svg"
                      )}
                    />
                  </span>
                </button>
              </div>
            </form>
          </div>
        )}

        <SubmitDialog
          show={submitDialog.isOpen}
          onHide={() => setSubmitDialog({ isOpen: false })}
          onSubmit={submit}
          message="Are you sure you want to do this?"
        />
      </div>
    </div>
  );
}
