import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { WebSocketLink } from "@apollo/client/link/ws";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { SnackbarProvider, useSnackbar } from "notistack";
import { getMainDefinition } from "@apollo/client/utilities";
import { API_ENDPOINT, API_WS_ENDPOINT } from "../config";

export default function App({ store, persistor, basename }) {
  const { NODE_ENV } = process.env;
  const { enqueueSnackbar } = useSnackbar();
  const locale = JSON.parse(localStorage.getItem("i18nConfig"));
  // let url = "connex.eoraa.com/graphql/";
  // if (
  //   NODE_ENV === "development" ||
  //   window.location.hostname.includes("testing")
  // ) {
  //   url = "testing-connex.eoraa.com/graphql/";
  // }
  const httplink = createHttpLink({
    uri: API_ENDPOINT,
  });
  const wsLink = new WebSocketLink({
    uri: `wss://${API_WS_ENDPOINT}`,
    options: {
      reconnect: true,
    },
  });
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httplink
  );
  const notify = async (res) => {
    if (res && res.errors) {
      res.errors.map((err) =>
        enqueueSnackbar(err.message, { variant: "error" })
      );
    } else if (res.data) {
      const key = Object.keys(res.data)[0];
      if (res.data[key].error)
        enqueueSnackbar(res.data[key].error, { variant: "error" });
    }
  };
  const resIntercept = new ApolloLink((operation, forward) => {
    if (operation.operationName === "ticketChatMessage")
      return forward(operation);
    return forward(operation).map((data) => {
      notify(data);
      return data;
    });
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        enqueueSnackbar(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          { variant: "error" }
        )
      );
    if (networkError)
      enqueueSnackbar(`[Network error]: ${networkError}`, { variant: "error" });
  });
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    // console.log('auth token', token);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: localStorage.getItem("token"),
        "Accept-Language": locale ? locale.selectedLang : "jp",
      },
    };
  });
  const client = new ApolloClient({
    link: from([authLink, resIntercept, errorLink, splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        // BannerPositionDatePricing: {
        //   fields: {
        //     price: {
        //       read(price) {
        //         // Return the cached name, transformed to upper case
        //         return `¥${price}`;
        //       },
        //     },
        //   },
        // },
        BannerPosition: {
          fields: {
            position: {
              read(position) {
                return parseInt(position);
              },
            },
          },
        },
      },
    }),
  });

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <ApolloProvider client={client}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}

          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                {/* <SnackbarProvider > */}
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
                </I18nProvider>
                {/* </SnackbarProvider> */}
              </MaterialThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
}
