import { gql } from "@apollo/client";

export const ALL_BANNERS = gql`
  query banners(
    $offset: Int!
    $limit: Int!
    $status: BannerStatus
    $advertiser_id: UUID
    $image_uploaded: Boolean
    $order: SortOrder
    $sort: String
    $min_created_at: Datetime
    $max_created_at: Datetime
  ) {
    banners(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiser_id
      status: $status
      image_uploaded: $image_uploaded
      order: $order
      sort: $sort
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      banners {
        id
        advertiser_id
        name
        image_uploaded
        link
        image
        image_extension
        created_at
        updated_at
        status
        status_description
      }
      total_count
    }
  }
`;
export const BANNER = gql`
  query banner($id: UUID!) {
    banner(id: $id) {
      error
      banner {
        id
        advertiser_id
        name
        image_uploaded
        link
        image
        image_extension
        created_at
        updated_at
        status
        status_description
        activities(reverse: true) {
          user_id
          banner_id
          action
          description
          id
          created_at
        }
      }
    }
  }
`;

export const VALIDATE = gql`
  query validateApi {
    validate {
      error
      user {
        id
        user_name
        group
      }
    }
  }
`;
export const ALL_USER = gql`
  query users(
    $offset: Int
    $limit: Int
    $group: String
    $max_created_at: Datetime
    $min_created_at: Datetime
  ) {
    users(
      offset: $offset
      limit: $limit
      group: $group
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      users {
        id
        user_name
        group
      }
      total_count
    }
  }
`;
export const ALL_ADVERTISER = gql`
  query advertisers(
    $offset: Int
    $limit: Int
    $email: String
    $active: Boolean
    $company_name: String
    $email_confirmed: Boolean
    $phone_no: String
    $order: SortOrder
    $sort: String
    $min_created_at: Datetime
    $max_created_at: Datetime
    $status: AdvertiserStatus
  ) {
    advertisers(
      offset: $offset
      limit: $limit
      email: $email
      active: $active
      phone_no: $phone_no
      company_name: $company_name
      email_confirmed: $email_confirmed
      status: $status
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
    ) {
      error
      advertisers {
        id
        user_name
        group
        email
        address_1
        address_2
        city
        state
        postal_code
        company_name
        sessions(reverse: true) {
          created_at
        }
        email_confirmed
        status
        phone_cc
        phone_no
        active
        status
        created_at
        credit
        updated_at
      }
      total_count
    }
  }
`;

export const ALL_CREDIT = gql`
  query credits(
    $offset: Int
    $limit: Int
    $user_id: UUID
    $bank_transaction_id: String
    $amount: Int
    $order: SortOrder
    $sort: String
    $min_created_at: Datetime
    $max_created_at: Datetime
  ) {
    credits(
      offset: $offset
      limit: $limit
      user_id: $user_id
      bank_transaction_id: $bank_transaction_id
      amount: $amount
      order: $order
      sort: $sort
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      credits {
        id
        user_id
        bank_transaction_id
        amount
        created_at
        updated_at
      }
      total_count
    }
  }
`;
export const ALL_MEETING_NOTES = gql`
  query meeting_notes(
    $offset: Int
    $limit: Int
    $user_id: UUID
    $recipient_id: UUID
    $order: SortOrder
    $sort: String
    $min_created_at: Datetime
    $max_created_at: Datetime
  ) {
    meeting_notes(
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      offset: $offset
      limit: $limit
      user_id: $user_id
      recipient_id: $recipient_id
      order: $order
      sort: $sort
    ) {
      error
      meeting_notes {
        id
        user_id
        recipient_id
        note
        created_at
        updated_at
        image_uploaded
        image_extension
        image
      }
      total_count
    }
  }
`;
export const MEETING_NOTE = gql`
  query meetingNote($id: UUID!) {
    meeting_note(id: $id) {
      error
      meeting_note {
        id
        user_id
        recipient_id
        note
        created_at
        updated_at
        image_uploaded
        image_extension
        image
      }
    }
  }
`;
export const ADVERTISER = gql`
  query advertiser($id: UUID) {
    advertiser(id: $id) {
      error
      advertiser {
        user_name
      }
    }
  }
`;

export const STAFF_LIST = gql`
  query StaffList(
    $offset: Int!
    $limit: Int!
    $email: String
    $name: String
    $active: Boolean
    $order: SortOrder
    $sort: String
    $min_created_at: Datetime
    $max_created_at: Datetime
  ) {
    staffs(
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      offset: $offset
      limit: $limit
      email: $email
      name: $name
      active: $active
      order: $order
      sort: $sort
    ) {
      error
      staffs {
        id
        user_name
        group
        email
        active
        name
        designation
        created_at
        updated_at
        sessions(reverse: true) {
          created_at
        }
      }
      total_count
    }
  }
`;
export const GET_STAFF = gql`
  query getStaff($id: UUID, $user_name: String, $email: String) {
    staff(id: $id, user_name: $user_name, email: $email) {
      error
      staff {
        id
        user_name
        group
        email
        active
        name
        designation
        created_at
        updated_at
      }
    }
  }
`;
export const GET_ADVERTISER = gql`
  query advertiser(
    $id: UUID
    $user_name: String
    $email: String
    $start: Int
    $stop: Int
    $limit: Int
  ) {
    advertiser(id: $id, user_name: $user_name, email: $email) {
      error
      advertiser {
        id
        user_name
        group
        email
        address_1
        address_2
        city
        state
        postal_code
        company_name
        email_confirmed
        active
        created_at
        updated_at
        credit
        phone_no
        phone_cc
        logo
        logo_image_extension
        logo_uploaded
        status
        status_description
        person_incharge_designation
        person_incharge_name
        company_website_url
        company_industry_area
        country
        activities(reverse: true, limit: $limit, start: $start, stop: $stop) {
          user_id
          advertiser_id
          action
          description
          id
          created_at
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: UUID, $user_name: String, $email: String) {
    user(id: $id, user_name: $user_name, email: $emai) {
      error
      user {
        id
        user_name
        email
        created_at
        updated_at
      }
    }
  }
`;
export const GET_BANNER_POSITION = gql`
  query getBannerPosition($id: UUID!) {
    banner_position(id: $id) {
      error
      banner_position {
        id
        position
        text
        standard_pricing
      }
    }
  }
`;
export const GET_BANNER_POSITIONS = gql`
  query getBannerPositions(
    $offset: Int
    $limit: Int
    $position: String
    $max_standard_pricing: Float
    $min_standard_pricing: Float
    $order: SortOrder
    $sort: String
  ) {
    banner_positions(
      offset: $offset
      limit: $limit
      position: $position
      max_standard_pricing: $max_standard_pricing
      min_standard_pricing: $min_standard_pricing
      order: $order
      sort: $sort
    ) {
      error
      banner_positions {
        id
        position

        standard_pricing
        created_at
        updated_at
        date_pricings {
          id
          banner_position_id
          price_on
          price
          created_at
          updated_at
        }
      }
      total_count
    }
  }
`;
export const GET_BANNER_PRICINGS = gql`
  query bannerPositionDatePricings(
    $offset: Int
    $limit: Int
    $banner_position_id: UUID
    $price_on: Date
    $price: Float
    $order: SortOrder
    $sort: String
    $min_price_on: Date
    $max_price_on: Date
    $min_created_at: Datetime
    $max_created_at: Datetime
  ) {
    banner_position_date_pricings(
      offset: $offset
      limit: $limit
      banner_position_id: $banner_position_id
      price_on: $price_on
      price: $price
      order: $order
      sort: $sort
      min_price_on: $min_price_on
      max_price_on: $max_price_on
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      banner_position_date_pricings {
        id
        banner_position_id
        price_on
        price
        created_at
        updated_at
      }
      total_count
    }
  }
`;
export const ALL_CAMPAIGNS_MIN = gql`
  query campaigns(
    $offset: Int
    $limit: Int
    $order: SortOrder
    $sort: String
    $advertiser_id: UUID
    $status: CampaignStatus
    $name: String
    $min_created_at: Datetime
    $max_created_at: Datetime
    $start_date: Date
    $end_date: Date
  ) {
    campaigns(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiser_id
      status: $status
      name: $name
      sort: $sort
      order: $order
      start_date: $start_date
      end_date: $end_date
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      campaigns {
        id
        name
        advertiser_id
        start_date
        end_date
        status
        status_description
        created_at
        updated_at
        price
        advertiser {
          user_name
          company_name
        }
      }
      total_count
    }
  }
`;
export const ALL_CAMPAIGNS_MIN_SLOTS = gql`
  query campaigns(
    $offset: Int
    $limit: Int
    $order: SortOrder
    $sort: String
    $advertiser_id: UUID
    $status: CampaignStatus
    $name: String
    $min_created_at: Datetime
    $max_created_at: Datetime
    $min_start_date: Date
    $max_start_date: Date
    $max_end_date: Date
    $start_date: Date
    $end_date: Date
  ) {
    campaigns(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiser_id
      status: $status
      name: $name
      sort: $sort
      order: $order
      start_date: $start_date
      end_date: $end_date
      min_start_date: $min_start_date
      max_end_date: $max_end_date
      max_start_date: $max_start_date
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      campaigns {
        id
        name
        start_date
        end_date
        slots {
          price
          date
          status
        }
      }
      total_count
    }
  }
`;
export const ALL_CAMPAIGNS = gql`
  query campaigns(
    $offset: Int
    $limit: Int
    $order: SortOrder
    $sort: String
    $advertiser_id: UUID
    $status: CampaignStatus
    $name: String
    $min_created_at: Datetime
    $max_created_at: Datetime
    $start_date: Date
    $end_date: Date
  ) {
    campaigns(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiser_id
      status: $status
      name: $name
      sort: $sort
      order: $order
      start_date: $start_date
      end_date: $end_date
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      campaigns {
        id
        name
        advertiser_id
        start_date
        end_date
        status
        status_description
        created_at
        updated_at
        price
        slots {
          id
          campaign_id
          banner_id
          banner_position_id
          price
          date
          created_at
          updated_at
          status
        }
        advertiser {
          user_name
        }
      }
      total_count
    }
  }
`;

export const SLOT_MIN_BANNER_POS = gql`
  query slots(
    $offset: Int
    $limit: Int
    $campaign_id: UUID
    $banner_id: UUID
    $banner_position_id: UUID
    $price: Int
    $date: Date
    $max_date: Date
    $min_date: Date
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
    $status: CampaignStatus
  ) {
    slots(
      offset: $offset
      limit: $limit
      campaign_id: $campaign_id
      banner_id: $banner_id
      banner_position_id: $banner_position_id
      price: $price
      date: $date
      max_date: $max_date
      min_date: $min_date
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
      status: $status
    ) {
      error
      slots {
        id
        price
        date
        campaign_id
        banner_position {
          position
        }
        advertiser {
          company_name
          id
        }
      }
      total_count
    }
  }
`;
export const SLOT_MIN = gql`
  query slots(
    $offset: Int
    $limit: Int
    $campaign_id: UUID
    $banner_id: UUID
    $banner_position_id: UUID
    $price: Int
    $date: Date
    $max_date: Date
    $min_date: Date
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
    $status: CampaignStatus
  ) {
    slots(
      offset: $offset
      limit: $limit
      campaign_id: $campaign_id
      banner_id: $banner_id
      banner_position_id: $banner_position_id
      price: $price
      date: $date
      max_date: $max_date
      min_date: $min_date
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
      status: $status
    ) {
      error
      slots {
        id
        price
        campaign_id
        date
      }
      total_count
    }
  }
`;

export const SLOT = gql`
  query slots(
    $offset: Int
    $limit: Int
    $campaign_id: UUID
    $banner_id: UUID
    $banner_position_id: UUID
    $price: Int
    $date: Date
    $max_date: Date
    $min_date: Date
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
    $status: CampaignStatus
  ) {
    slots(
      offset: $offset
      limit: $limit
      campaign_id: $campaign_id
      banner_id: $banner_id
      banner_position_id: $banner_position_id
      price: $price
      date: $date
      max_date: $max_date
      min_date: $min_date
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
      status: $status
    ) {
      error
      slots {
        id
        campaign_id
        banner_id
        advertiser {
          company_name
          id
        }
        banner_position_id
        banner_position {
          position
        }
        view_count
        click_count
        price
        date
        created_at
        updated_at
        status
      }
      total_count
    }
  }
`;

export const GET_SLOT = gql`
  query slot($id: UUID!) {
    slot(id: $id) {
      error
      slot {
        id
        campaign_id
        banner_id
        banner_position_id
        banner_position
        price
        date
        created_at
        updated_at
      }
    }
  }
`;
export const CAMPAIGN = gql`
  query campaign($id: UUID!) {
    campaign(id: $id) {
      error
      campaign {
        id
        name
        advertiser_id
        advertiser {
          user_name
        }
        slots {
          view_count
          click_count
        }
        start_date
        end_date
        status
        created_at
        updated_at
        activities(reverse: true) {
          user_id
          campaign_id
          action
          description
          id
          created_at
        }
      }
    }
  }
`;

export const PROFESSIONALS_MIN = gql`
  query professionals(
    $offset: Int
    $limit: Int
    $email: String
    $phone_cc: String
    $first_name: String
    $last_name: String
    $designation: String
    $organization: String
    $specialisation: String
    $email_confirmed: Boolean
    $phone_confirmed: Boolean
    $DOB: Date
    $status: ProfessionalStatus
    $user_name: String
    $order: SortOrder
    $sort: String
    $min_created_at: Datetime
    $max_created_at: Datetime
  ) {
    professionals(
      offset: $offset
      limit: $limit
      email: $email
      phone_cc: $phone_cc
      first_name: $first_name
      last_name: $last_name
      user_name: $user_name
      designation: $designation
      organization: $organization
      specialisation: $specialisation
      email_confirmed: $email_confirmed
      phone_confirmed: $phone_confirmed
      DOB: $DOB
      status: $status
      order: $order
      sort: $sort
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      professionals {
        id
      }
      total_count
    }
  }
`;
export const PROFESSIONALS_STORAGE = gql`
  query professionals(
    $offset: Int
    $limit: Int
    $email: String
    $phone_cc: String
    $first_name: String
    $last_name: String
    $designation: String
    $organization: String
    $specialisation: String
    $email_confirmed: Boolean
    $phone_confirmed: Boolean
    $DOB: Date
    $status: ProfessionalStatus
    $order: SortOrder
    $sort: String
    $min_created_at: Datetime
    $max_created_at: Datetime
  ) {
    professionals(
      offset: $offset
      limit: $limit
      email: $email
      phone_cc: $phone_cc
      first_name: $first_name
      last_name: $last_name
      designation: $designation
      organization: $organization
      specialisation: $specialisation
      email_confirmed: $email_confirmed
      phone_confirmed: $phone_confirmed
      DOB: $DOB
      status: $status
      order: $order
      sort: $sort
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      professionals {
        available_storage
      }
      total_count
    }
  }
`;
export const PROFESSIONALS = gql`
  query professionals(
    $offset: Int
    $limit: Int
    $email: String
    $phone_cc: String
    $first_name: String
    $last_name: String
    $designation: String
    $organization: String
    $specialisation: String
    $email_confirmed: Boolean
    $phone_confirmed: Boolean
    $user_name: String
    $DOB: Date
    $status: ProfessionalStatus
    $order: SortOrder
    $sort: String
    $min_created_at: Datetime
    $max_created_at: Datetime
  ) {
    professionals(
      offset: $offset
      limit: $limit
      email: $email
      phone_cc: $phone_cc
      first_name: $first_name
      last_name: $last_name
      user_name: $user_name
      designation: $designation
      organization: $organization
      specialisation: $specialisation
      email_confirmed: $email_confirmed
      phone_confirmed: $phone_confirmed
      DOB: $DOB
      status: $status
      order: $order
      sort: $sort
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      professionals {
        id
        user_name
        group
        email
        first_name
        sessions(reverse: true) {
          created_at
        }
        last_name
        designation
        organization
        specialisation
        email_confirmed
        DOB
        docs {
          id
          status
          doc_type
        }
        status
        status_description
        active
        created_at
        updated_at
      }
      total_count
    }
  }
`;
export const PROFESSIONAL_ACTIVITIES = gql`
  query professional($id: UUID!) {
    professional(id: $id) {
      error
      professional {
        activities(reverse: true, limit: 5) {
          action
          description
          id
          created_at
        }
      }
    }
  }
`;
export const PROFESSIONAL_STORAGE = gql`
  query professionalStorage($id: UUID!) {
    professional(id: $id) {
      error
      professional {
        available_storage
      }
    }
  }
`;
export const PROFESSIONAL = gql`
  query professional($id: UUID!) {
    professional(id: $id) {
      error
      professional {
        id
        user_name
        group
        email
        first_name
        last_name
        designation
        organization
        specialisation
        email_confirmed
        phone_cc
        phone_no
        docs {
          id
          doc_type
          status
          professional_id
          file
          created_at
        }
        DOB
        status

        status_description
        active
        created_at
        updated_at
      }
    }
  }
`;
export const TRANSACTIONS = gql`
  query transactions(
    $offset: Int
    $limit: Int
    $user_id: UUID
    $amount: Int
    $ref_id: String
    $status: TransactionStatus
    $transaction_type: TransactionType
    $order: SortOrder
    $sort: String
    $min_created_at: Datetime
    $max_created_at: Datetime
  ) {
    transactions(
      offset: $offset
      limit: $limit
      user_id: $user_id
      amount: $amount
      ref_id: $ref_id
      status: $status
      transaction_type: $transaction_type
      order: $order
      sort: $sort
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      error
      transactions {
        id
        user_id
        amount
        ref_id
        user {
          user_name
        }
        transaction_type
        status
        created_at
        updated_at
      }
      total_count
    }
  }
`;
export const PROFESSIONAL_DOCS = gql`
  query professionals_docs(
    $offset: Int
    $limit: Int
    $professional_id: UUID
    $doc_type: ProfessionalDocType
    $status: ProfessionalDocStatus
    $doc_uploaded: Boolean
    $order: SortOrder
    $sort: String
  ) {
    professional_docs(
      offset: $offset
      limit: $limit
      professional_id: $professional_id
      doc_type: $doc_type
      status: $status
      doc_uploaded: $doc_uploaded
      order: $order
      sort: $sort
    ) {
      error
      professional_docs {
        id
        professional_id
        doc_type
        file
        doc_extension
        doc_uploaded
        status
        status_description
        created_at
        updated_at
      }
      total_count
    }
  }
`;
export const PROFESSIONAL_DOC = gql`
  query professional_doc($id: UUID!) {
    professional_doc(id: $id) {
      error
      professional_doc {
        id
        professional_id
        doc_type
        doc_extension
        doc_uploaded
        status
        status_description
        file
        created_at
        updated_at
      }
    }
  }
`;
export const MASTERLIST_SPECIALISATIONS = gql`
  query masterlist_specialisations(
    $offset: Int
    $limit: Int
    $major_classification: String
    $middle_classification: String
    $sub_category: String
    $specific_category: String
    $order: SortOrder
    $sort: String
  ) {
    masterlist_specialisations(
      offset: $offset
      limit: $limit
      major_classification: $major_classification
      middle_classification: $middle_classification
      sub_category: $sub_category
      specific_category: $specific_category
      order: $order
      sort: $sort
    ) {
      error
      masterlist_specialisations {
        id
        major_classification
        middle_classification
        sub_category
        specific_category
        created_at
        locale
        updated_at
      }
      total_count
    }
  }
`;

export const MASTERLIST_SPECIALISATION = gql`
  query masterlist_specialisation($id: UUID) {
    masterlist_specialisation(id: $id) {
      error
      masterlist_specialisation {
        id
        major_classification
        middle_classification
        sub_category
        locale
        specific_category
        created_at
        updated_at
      }
    }
  }
`;
export const ADVERTISER_TICKETS = gql`
  query advertiserTickets(
    $offset: Int
    $limit: Int
    $advertiser_id: UUID
    $assigned_to: UUID
    $status: AdvertiserTicketStatus
    $order: SortOrder
    $sort: String
    $min_created_at: Datetime
    $max_created_at: Datetime
  ) {
    advertiser_tickets(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiser_id
      assigned_to: $assigned_to
      status: $status
      order: $order
      sort: $sort
      min_created_at: $min_created_at
      max_created_at: $max_created_at
    ) {
      advertiser_tickets {
        id
        advertiser_id
        advertiser {
          user_name
        }
        description
        messages {
          seen
          created_at
        }
        assigned_to
        meeting_note_id
        status
        status_description
        created_at
        updated_at
      }
      total_count
    }
  }
`;

export const ADVERTISER_TICKET = gql`
  query advertiser_ticket($id: UUID) {
    advertiser_ticket(id: $id) {
      error
      advertiser_ticket {
        id
        advertiser_id
        advertiser {
          user_name
        }
        description
        assigned_to
        status
        status_description
        created_at
        updated_at
      }
    }
  }
`;
export const TICKET_CHAT_MESSAGES = gql`
  query ticketChatMessages(
    $ticket_id: UUID!
    $start: String
    $stop: String
    $limit: Int
    $reverse: Boolean
  ) {
    ticket_chat_messages(
      ticket_id: $ticket_id
      start: $start
      stop: $stop
      limit: $limit
      reverse: $reverse
    ) {
      error
      ticket_chat_messages {
        id
        ticket_id
        message
        sent_by
        seen
        created_at
      }
    }
  }
`;

export const ADVERTISER_UPDATE_REQUESTS = gql`
  query advertiserUpdateRequests(
    $offset: Int
    $limit: Int
    $advertiser_id: UUID
    $status: AdvertiserUpdateRequestStatus
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
  ) {
    advertiser_update_requests(
      offset: $offset
      limit: $limit
      advertiser_id: $advertiser_id
      status: $status
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
    ) {
      error
      advertiser_update_requests {
        id
        advertiser_id
        status
        status_description
        email
        address_1
        address_2
        city
        logo
        state
        country
        postal_code
        company_name
        company_industry_area
        company_website_url
        person_incharge_name
        person_incharge_designation
        person_incharge_phone_cc
        person_incharge_phone_no
        phone_cc
        phone_no
        logo_image_extension
        logo_uploaded
        created_at
        updated_at
      }
      total_count
    }
  }
`;
export const POSTS_MIN = gql`
  query posts(
    $offset: Int
    $limit: Int
    $professional_id: UUID
    $image_uploaded: Boolean
    $status: PostStatus
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
  ) {
    posts(
      offset: $offset
      limit: $limit
      professional_id: $professional_id
      image_uploaded: $image_uploaded
      status: $status
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
    ) {
      error
      total_count
    }
  }
`;

export const POSTS = gql`
  query posts(
    $offset: Int
    $limit: Int
    $professional_id: UUID
    $image_uploaded: Boolean
    $status: PostStatus
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
  ) {
    posts(
      offset: $offset
      limit: $limit
      professional_id: $professional_id
      image_uploaded: $image_uploaded
      status: $status
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
    ) {
      error
      posts {
        id
        professional_id
        content
        image_uploaded
        image_extension
        created_at
        updated_at
        status
        status_description
        tags {
          id
          masterlist_topic {
            topic
          }
        }
        image
      }
      total_count
    }
  }
`;

export const VIDEOS = gql`
  query videos(
    $offset: Int
    $limit: Int
    $professional_id: UUID
    $allow_advertisement_use: Boolean
    $status: VideoStatus
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
  ) {
    videos(
      offset: $offset
      limit: $limit
      professional_id: $professional_id
      allow_advertisement_use: $allow_advertisement_use
      status: $status
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
    ) {
      error
      videos {
        id
        professional_id
        title
        description
        allow_advertisement_use
        created_at
        updated_at
        status
        status_description
      }
      total_count
    }
  }
`;

export const BANNER_POSITION_MONTH_PRICINGS = gql`
  query bannerPositionMonthPricings(
    $offset: Int
    $limit: Int
    $banner_position_id: UUID
    $month: Int
    $price: Int
    $min_month: Int
    $max_month: Int
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
  ) {
    banner_position_month_pricings(
      offset: $offset
      limit: $limit
      banner_position_id: $banner_position_id
      month: $month
      price: $price
      min_month: $min_month
      max_month: $max_month
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
    ) {
      error
      banner_position_month_pricings {
        id
        banner_position_id
        month
        price
      }
      total_count
    }
  }
`;
export const POST_VIDEO_REPORTS_COUNT = gql`
  query post_video_reports(
    $offset: Int
    $limit: Int
    $post_id: UUID
    $video_id: UUID
    $reporter_id: UUID
    $category: PostVideoReportCategory
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
    $action_taken: Boolean
  ) {
    post_video_reports(
      offset: $offset
      limit: $limit
      post_id: $post_id
      video_id: $video_id
      reporter_id: $reporter_id
      category: $category
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
      action_taken: $action_taken
    ) {
      error
      total_count
    }
  }
`;
export const POST_VIDEO_REPORTS_MIN = gql`
  query post_video_reports(
    $offset: Int
    $limit: Int
    $post_id: UUID
    $video_id: UUID
    $reporter_id: UUID
    $category: PostVideoReportCategory
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
    $action_taken: Boolean
  ) {
    post_video_reports(
      offset: $offset
      limit: $limit
      post_id: $post_id
      video_id: $video_id
      reporter_id: $reporter_id
      category: $category
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
      action_taken: $action_taken
    ) {
      error
      post_video_reports {
        id
        post_id
        video_id
        reporter_id
        name
        email
        description
        action_taken
        category
        reporter {
          user_name
        }

        created_at
        updated_at
      }
      total_count
    }
  }
`;

export const POST_VIDEO_REPORTS = gql`
  query post_video_reports(
    $offset: Int
    $limit: Int
    $post_id: UUID
    $video_id: UUID
    $reporter_id: UUID
    $category: PostVideoReportCategory
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
    $action_taken: Boolean
  ) {
    post_video_reports(
      offset: $offset
      limit: $limit
      post_id: $post_id
      video_id: $video_id
      reporter_id: $reporter_id
      category: $category
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
      action_taken: $action_taken
    ) {
      error
      post_video_reports {
        id
        post_id
        video_id
        reporter_id
        name
        email
        description
        action_taken
        category
        reporter {
          user_name
        }
        post {
          status
          professional {
            user_name
          }
        }
        created_at
        updated_at
      }
      total_count
    }
  }
`;

export const POST_VIDEO_REPORT = gql`
  query post_video_report($id: UUID!) {
    post_video_report(id: $id) {
      error
      post_video_report {
        id
        post_id
        video_id
        reporter_id
        name
        email
        description
        category
        created_at
        updated_at
        action_taken
        reporter {
          user_name
        }
        post {
          content
          status
        }
      }
    }
  }
`;
export const FAQS = gql`
  query faqs(
    $offset: Int
    $limit: Int
    $question: String
    $answer: String
    $type: FAQType
    $min_created_at: Datetime
    $max_created_at: Datetime
    $order: SortOrder
    $sort: String
  ) {
    faqs(
      offset: $offset
      limit: $limit
      question: $question
      answer: $answer
      type: $type
      min_created_at: $min_created_at
      max_created_at: $max_created_at
      order: $order
      sort: $sort
    ) {
      error
      faqs {
        id
        question
        jpQuestion
        jpAnswer
        answer
        type
        created_at
        updated_at
      }
    }
  }
`;
export const POST = gql`
  query post($id: UUID!) {
    post(id: $id) {
      error
      post {
        id
        image
        status
        content
        created_at
        updated_at
      }
    }
  }
`;

export const VIDEO = gql`
  query video($id: UUID!) {
    video(id: $id) {
      error
      video {
        id
        professional_id
        title
        description
        status
        created_at
        updated_at
      }
    }
  }
`;
