/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useIntl } from "react-intl";

export function AsideMenuList({ layoutProps }) {
  const intl = useIntl();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        <li className={`menu-item ${getMenuItemActive("/dashboard")}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "DASHBOARD" })}
            </span>
          </NavLink>
        </li>

        <li className="menu-section ">
          <h4 className="menu-text">
            {intl.formatMessage({ id: "ASIDE.LIST.ADVERTISEMENT" })}
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive("/Advertisers")}`}>
          <NavLink className="menu-link" to="/Advertisers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Magic.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "COMPANY" })}
            </span>
          </NavLink>
        </li>

        {/*begin::2 Level*/}
        <li className={`menu-item ${getMenuItemActive("/banner/approval")}`}>
          <NavLink className="menu-link" to="/banner/approval">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Stamp.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "BANNER" })}
            </span>
          </NavLink>
        </li>
        {/*end::2 Level*/}

        {/*begin::2 Level*/}
        <li className={`menu-item ${getMenuItemActive("/banner/campaign")}`}>
          <NavLink className="menu-link" to="/banner/campaign">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "CAMPAIGN" })}
            </span>
          </NavLink>
        </li>
        {/*end::2 Level*/}

        {/*begin::2 Level*/}
        <li className={`menu-item ${getMenuItemActive("/banner/setting")}`}>
          <NavLink className="menu-link" to="/banner/setting">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "SETTINGS" })}
            </span>
          </NavLink>
        </li>

        <li className="menu-section ">
          <h4 className="menu-text">
            {intl.formatMessage({ id: "ASIDE.LIST.USER" })}
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive("/User")}`}>
          <NavLink className="menu-link" to="/User">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "USERSLIST" })}
            </span>
          </NavLink>
        </li>

        <li className="menu-section ">
          <h4 className="menu-text">
            {intl.formatMessage({ id: "ASIDE.LIST.REPORTS" })}
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive("/Flag", false)}`}>
          <NavLink className="menu-link" to="/Flag">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}
              />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "FLAGS" })}
            </span>
          </NavLink>
        </li>
        <li className="menu-section ">
          <h4 className="menu-text">
            {intl.formatMessage({ id: "ASIDE.LIST.SETTINGS" })}
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive("/admin/staff", false)}`}>
          <NavLink className="menu-link" to="/admin/staff">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "STAFF" })}
            </span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/transactions",
            false
          )}`}
        >
          <NavLink className="menu-link" to="/admin/transactions">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "ACCOUNTS" })}
            </span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/email", false)}`}>
          <NavLink className="menu-link" to="/email">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "EMAIL" })}
            </span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/faq",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/faq">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Article.svg")} />
            </span>
            <span className="menu-text">
              {" "}
              {intl.formatMessage({ id: "FAQ" })}
            </span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">
                    {" "}
                    {intl.formatMessage({ id: "FAQ" })}
                  </span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/faq/advertiser")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/faq/advertiser">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    {intl.formatMessage({ id: "Advertiser" })}
                  </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/faq/professional"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/faq/professional">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    {intl.formatMessage({ id: "Professional" })}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li className="menu-section ">
          <h4 className="menu-text">{intl.formatMessage({ id: "SUPPORT" })}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive("/tickets", false)}`}>
          <NavLink className="menu-link" to="/tickets">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Chat5.svg")}
              />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "SUPPORT" })}
            </span>
          </NavLink>
        </li>
        {/* <li className={`menu-item ${getMenuItemActive("/faqs", false)}`}>
          <NavLink className="menu-link" to="/faqs">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Chat5.svg")}
              />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "FAQ" })}
            </span>
          </NavLink>
        </li> */}

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
