import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import SkeletonTable from "./SkeletonTable";
import SkeletonElement from "./SkeletonElement";

const SkeletonFinal = () => {
  return (
    <Card>
      {/* <LoadingDialog isLoading={loading} text="loading..." /> */}

      <CardHeader>
        <CardHeaderToolbar>
          <SkeletonElement type="tab" />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group d-flex">
          <SkeletonElement type="tab" />
          <SkeletonElement type="tab" />
          <SkeletonElement type="tab" />
        </div>
        <SkeletonTable theme="light" />
      </CardBody>
    </Card>
  );
};

export default SkeletonFinal;
