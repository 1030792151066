import React from 'react'
import './skeleton.scss'

const Shimmer = () => {
    return (
        // <div className="shimmer-wraper">
        //     <div className="shimmer">

        //     </div>
        // </div>
        <div>

        </div>
    )
}

export default Shimmer;