import { gql } from "@apollo/client";

export const TICKET_CHAT_MESSAGE = gql`
  subscription ticketChatMessage($token: String!, $ticket_id: UUID!) {
    ticket_chat_message(token: $token, ticket_id: $ticket_id) {
      error
      event
      ticket_chat_message {
        id
        ticket_id
        message
        sent_by
        seen
        created_at
      }
      typing_user_id
    }
  }
`;
